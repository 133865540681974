import React, { useState, useLayoutEffect } from 'react'

// Components
import Image from 'components/shared/Image'
import CustomLink from 'components/shared/CustomLink'
import ParseContent from 'components/shared/ParseContent'

// Third Party
import styled, { css } from 'styled-components'
import moment from 'moment'
import nl from 'moment/locale/nl'
import { motion } from 'framer-motion'
import { useInView } from 'react-hook-inview'

// Images
import profArrow from 'img/prof_arrow.svg'

const StyledImage = styled(Image)`
  min-height: 200px;
`

const StyledVideo = styled(motion.div)`
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  max-width: 500px;
  min-width: 300px;

  @media screen and (max-width: 1199px) {
    max-width: 370px;
    min-width: 300px;
    position: relative;

    ${props => props.spotlight && css`
      top: 50px;
      left: 50px;
    `}
  }

  @media screen and (max-width: 991px) {
    ${props => props.spotlight && css`
      top: 0;
      left: 0;
    `}
  }

  @media screen and (max-width: 400px) {
    min-width: 300px;
  }
`

const ImgContainer = styled.div`
  position: relative;
`

const Time = styled.p`
  position: absolute;
  bottom: 0px;
  right: 15px;
  padding: 2px 8px 2px 8px;
  color: ${props => props.theme.color.text.light};
  font-weight: ${props => props.theme.font.weight.xl};
  font-family: ${props => props.theme.font.family.secondary};
  font-size: ${props => props.theme.font.size.sm};
  background-color: rgba(0,0,0,0.54);
  z-index: 2;
`

const Content = styled.div`
  background-color: ${props => props.theme.color.face.light};
`

const Arrow = styled.img`
  width: 50px;
  height: 50px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
`

const Video = ({ delay, spotlight, className, video }) => {
  const [ref, isVisible] = useInView({
    threshold: 0.4,
  })

  const [view, setView] = useState(false)

  useLayoutEffect(() => {
    if(isVisible && !view) {
      setView(true)
    }
  }, [isVisible])

  moment().locale('nl')

  let time = moment.duration(moment().diff(moment(video.acf.preview.date, "DD/MM/YYYY"))).humanize()

  return (
    <StyledVideo 
      ref={ref}
      spotlight={spotlight} 
      className={`mb-5 mb-lg-0 ${className ? `${className}` : ``}`}
      initial={{ y: 50, opacity: 0 }}
      animate={isVisible || view ? { y: 0, opacity: 1 } : { y: 50, opacity: 0 }}
      transition={{ type: 'spring', damping: 20, delay: delay}}
    >
      <CustomLink external newPage to={video.acf.preview.url}>
        <ImgContainer>
          <StyledImage src={video.acf.preview.image} />
          <Arrow src={profArrow} alt="" />
          <Time>{video.acf.preview.length}</Time>
        </ImgContainer>
        <Content className="px-3 py-2">
          <ParseContent content={video.title} className="mb-0 font-weight-xl font-family-secondary" />
          <p className="mb-0 font-weight-xxs font-family-secondary">{`${time} geleden`}</p>
        </Content>
      </CustomLink>
    </StyledVideo>
  )
}

export default Video