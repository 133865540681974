/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
import React from 'react'
import styled from 'styled-components'
import ButtonShell from './ButtonShell'

const StyledButton = styled.div`
  background-color: ${props => props.theme.color.face.contrast};
  display: inline-block;
  border-radius: 25.5px;
  
  & > a, & > button {
    color: ${props => props.theme.color.text.secondary};
    padding: 13.5px 35px;
    width: 100%;
    height: 100%;
    display: block;
    font-family: ${props => props.theme.font.family.secondary};
    font-weight: ${props => props.theme.font.weight.xl};

    &:hover {
      color: ${props => props.theme.color.text.secondary};
      text-decoration: none;
    }
  }
`

const ButtonDefault = ({ isAnchor, isCustom, to, children, className }) => (
  <StyledButton className={className}>
    {isCustom ? children : (
      <ButtonShell to={to} isAnchor={isAnchor}>
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonDefault