/* eslint-disable import/no-extraneous-dependencies */
import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import ImageBackground from 'components/shared/ImageBackground'
import GravityForm from 'components/shared/GravityForm'
import ParseContent from 'components/shared/ParseContent'
import CustomLink from 'components/shared/CustomLink'

// Third Party
import styled, { css } from 'styled-components'

// Images
import prof_facebook from 'img/prof_facebook.svg'
import prof_linkedin from 'img/prof_linkedin.svg'
import prof_twitter from 'img/prof_twitter.svg'
import prof_instagram from 'img/prof_instagram.svg'
import prof_youtube from 'img/prof_youtube.svg'
import phone from 'img/prof_phone.svg'
import email from 'img/landing/email.svg'

const StyledFooter = styled(ImageBackground)`
  color: ${props => props.theme.color.text.secondary};
  position: relative;

  ${props => props.extraSpacing && css`
    margin-top: 150px;
  `}
`

const InnerFooter = styled.div`
  position: relative;
  top: -100px;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  padding: 40px 70px 40px 70px;

  @media screen and (max-width: 991px) {
    padding: 40px 30px 40px 30px;
  }

  a {
    &:hover {
      color: ${props => props.theme.color.text.main};
    }
  }
`

const Content = styled(ParseContent)`
  p {
    white-space: nowrap;
    color: ${props => props.theme.color.text.contrast};
    font-family: ${props => props.theme.font.family.secondary};
    margin-bottom: 0;

    @media screen and (max-width: 991px) {
      text-align: center;
    }
  }
`

const EmailPhone = styled.div`
  img {
    height: 24px;
    width: 24px;
  }
`

const PrivacyLink = styled(CustomLink)`
  background-color: #FFFFFF;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
`

const Footer = ({ extraSpacing }) => {
  const {
    fields,
    background
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          info {
            description
            email {
              title
              url
            }
            phone {
              title
              url
            }
            phone_2 {
              title
              url
            }
            youtube
            instagram
            twitter
            linkedin
            facebook
          }
        }
      }

      background: file(relativePath: {eq: "prof_footer.jpg"}) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  let isContact = false

  if (typeof window !== 'undefined' && window.location.pathname.includes('contact')) {
    isContact = true
  }

  return (
    <StyledFooter extraSpacing={extraSpacing} local src={background}>
        <InnerFooter className="container d-flex flex-wrap align-items-center">
          <div className="row w-100 pb-5">
            <div className="col-lg-4">
              <Content content={fields.acf.info.description} />
              <EmailPhone className="py-lg-2 py-3 d-flex align-items-center">
              <a className="font-family-secondary font-weight-xl color-main" href={fields.acf.info.email.url}>
                <img className="mr-3" src={email} alt="" />
                {fields.acf.info.email.title}
              </a>
            </EmailPhone>
            </div>

            <div className="col-lg-4">
              <EmailPhone className="py-lg-0 py-3 mb-2 d-flex align-items-center">
                <a className="font-family-secondary font-weight-xl color-main" href={fields.acf.info.phone.url}>
                  <img className="mr-3" src={phone} alt="" />
                  {fields.acf.info.phone.title}
                </a>
              </EmailPhone>
              <EmailPhone className="py-lg-0 py-3 d-flex align-items-center">
                <a className="font-family-secondary font-weight-xl color-main" href={fields.acf.info.phone_2.url}>
                  <img className="mr-3" src={phone} alt="" />
                  {fields.acf.info.phone_2.title}
                </a>
              </EmailPhone>
            </div>
            <div className="col-lg-4 pt-lg-0 pt-4 pl-lg-4 d-flex justify-content-center align-items-center">
              <a href={fields.acf.info.youtube}> <img className="mr-3" src={prof_youtube} alt="" /></a>
              <a href={fields.acf.info.instagram}> <img className="mr-3" src={prof_instagram} alt="" /></a>
              <a href={fields.acf.info.twitter}> <img className="mr-3" src={prof_twitter} alt="" /></a>
              <a href={fields.acf.info.linkedin}> <img className="mr-3" src={prof_linkedin} alt="" /></a>
              <a href={fields.acf.info.facebook}> <img src={prof_facebook} alt="" /></a>
            </div>
          </div>
          {!isContact && (

          <div className="row w-100">
            
            <div className="col-lg-6">
              <p className="font-size-m font-family-secondary font-weight-xl color-main text-left mb-3 mb-lg-0">Wil je op de hoogte worden gehouden van nieuwe filmpjes? Vul dan je e-mailadres in.</p>
            </div>
            <div className="col-lg-6">
              <GravityForm submitStyle="white" newsletter="white" id={2} />
            </div>
            
          </div>
          )}

        </InnerFooter>
        <div className="d-flex justify-content-center pb-4">
          <PrivacyLink to="/privacyverklaring/" className="font-size-l color-third">
            Privacyverklaring & Disclaimer
          </PrivacyLink>
        </div>
    </StyledFooter>
  )
}

export default Footer
