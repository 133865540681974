/* eslint-disable import/no-named-as-default */
/* eslint-disable import/extensions */
/* eslint-disable import/no-unresolved */
/* eslint-disable no-console */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from "gatsby"
import { v4 } from 'uuid'

// Libraries
import Select from 'react-select'

// Components
import ButtonDefault from 'components/elements/ButtonDefault'
import ButtonOutline from 'components/elements/ButtonOutline'
import ButtonContrast from 'components/elements/ButtonContrast'
import Content from './Content'
import Loading from './Loading'

const StyledForm = styled.form`
  position: relative;
`

const ConfirmationMessage = styled(Content)`
  font-size: ${props => props.theme.font.size.xxl};
  font-weight: ${props => props.theme.font.weight.m};
  color: ${props => props.theme.color.text.third};
  text-align: center;
`

const Label = styled.label`
  display: block;
`

const Mandatory = styled.span`
  font-size: ${props => props.theme.font.size.xm};
  color: ${props => props.theme.color.face.contrast};
  margin-left: 5px;
`

const GravityForm = ({ bigTextArea, submitStyle, newsletter, className, id }) => {
  const { allGfForm: { edges: gravityData } } = useStaticQuery(graphql`
    query {
      allGfForm {
        edges {
          node {
            formId
            slug
            apiURL
            descriptionPlacement
            formFields {
              id
              label
              labelPlacement
              description
              descriptionPlacement
              type
              choices
              content
              errorMessage
              inputMaskValue
              isRequired
              visibility
              cssClass
              placeholder
              size
              defaultValue
              maxLength
            }
            button {
              text
            }
            confirmations {
              message
            }
          }
        }
      }
    }
  `)

  const { node: gfForm } = gravityData.filter(({ node }) => node.formId === id)[0]

  const [fields, setFields] = useState({})
  const [form, setForm] = useState(gfForm)
  const [status, setStatus] = useState('')
  const [message, setMessage] = useState('')

  useEffect(() => {
    const tempForm = form

    // add submit button as a field
    if (tempForm.formFields.filter((item) => item.type === "submit").length === 0)  {
      tempForm.formFields = [
        ...tempForm.formFields,
        {
          formId: v4(),
          type: "submit",
          text: tempForm.button.text
        }
      ]
    }

    setForm({
      ...form,
      ...tempForm
    })
  }, [])

  async function handleOnSubmit(event) {
    event.preventDefault()

    if (status === 'processing') {
      return
    }

    setStatus('processing')

    try {
      const formData = new FormData()

      for (const [key, value] of Object.entries(fields)) {
        formData.append(key, value)
      }

      const request = await fetch(`${form.apiURL}/submissions`, {
        method: 'POST',
        body: formData
      })

      const response = await request.json()
      if (response.is_valid === true) {
        setStatus('done')

        setMessage(response.confirmation_message)
      } else {
        setStatus('error')
        console.log(response)
      }
    } catch (error) {
      setStatus('error')
      console.error(error)
    }
  }

  function handleFieldChange(event) {
    // eslint-disable-next-line prefer-destructuring
    let {value} = event.target || event.value

    if (event.target) {
      if (event.target.type === "checkbox") {
        value = event.target.checked ? event.target.value : ""
      }

      setFields({
        ...fields,
        [event.target.name]: value
      })
    } else {
      setFields({
        ...fields,
        [event.name]: event.value
      })
    }
  }

  if (status === "done") {
    return (
      <div className="row py-lg-5 py-3 justify-content-center">
        <ConfirmationMessage className="col-lg-8" content={message} />
      </div>
    )
  }

  if (form.formFields) {
    return (
      <StyledForm id={`form_${gfForm.formId}`} className={`row ${className ? `${className}` : ``}`} method="post" onSubmit={handleOnSubmit}>
        {status === "processing" && (
          <Loading />
        )}
        {!newsletter ? (
          <>
            <div className="col-lg-6 pr-lg-5">
              {form.formFields && form.formFields.map((field, key) => {
                if (Array.isArray(field)) {
                  return (
                    <div key={key}>
                      {field.map((item, index) => (
                        <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                      ))}
                    </div>
                  )
                } 

                if (field.type === 'text' || field.type === 'phone' || field.type === 'email') {
                  return (
                    <div key={key}>
                      <FormField submitStyle={submitStyle} newsletter={newsletter} field={field} fields={fields} onChange={handleFieldChange} />
                    </div>
                  )
                }
              })}
            </div>
            <div className="col-lg-6 pl-lg-5">
              {form.formFields && form.formFields.map((field, key) => {
                if (Array.isArray(field)) {
                  return (
                    <div key={key}>
                      {field.map((item, index) => (
                        <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                      ))}
                    </div>
                  )
                }

                if (field.type === 'date' || field.type === 'textarea') {
                  return (
                    <div key={key}>
                      <FormField bigTextArea={bigTextArea} submitStyle={submitStyle} newsletter={newsletter} field={field} fields={fields} onChange={handleFieldChange} />
                    </div>
                  )
                }
              })}
            </div>
            <div className="col-lg-12">
              {form.formFields && form.formFields.map((field, key) => {
                if (Array.isArray(field)) {
                  return (
                    <div key={key}>
                      {field.map((item, index) => (
                        <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                      ))}
                    </div>
                  )
                }

                if (field.type === 'submit') {
                  return (
                    <div key={key}>
                      <FormField className="d-flex justify-content-center py-5" submitStyle={submitStyle} full newsletter={newsletter} field={field} fields={fields} onChange={handleFieldChange} />
                    </div>
                  )
                }
              })}
            </div>
          </>
        ) : (
          <>
            {form.formFields && form.formFields.map((field, key) => {
              if (Array.isArray(field)) {
                return (
                  <div key={key}>
                    {field.map((item, index) => (
                      <FormField key={index} field={item} fields={fields} onChange={handleFieldChange} />
                    ))}
                  </div>
                )
              }
    
              let c = 'col-lg-6'
    
              switch (field.type) {
                case 'submit':
                  c = 'col-lg-4'
                  break
                case 'email':
                  c = 'col-lg-8'
                  break
                default:
                  break
              }
    
              return (
                <div className={newsletter ? c : `col-lg-12`} key={key}>
                  <FormField submitStyle={submitStyle} newsletter={newsletter} field={field} fields={fields} onChange={handleFieldChange} />
                </div>
              )
            })}
          </>
        )}
        
      </StyledForm>
    )
  }

  console.error("No gravity forms found with id", id)
  return false;
}

const StyledTextField = styled.div`
  width: 100%;
  height: 100%;

  ${Label} {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
    color: ${props => props.theme.color.text.third};
  }

  input {
    height: 51px;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.sm};
    padding: 5px 20px 5px 20px;

    ${props => props.submitStyle === 'white' && css`
      border: 1px solid ${props => props.theme.color.text.placeholder};
    `}

    &::placeholder {
      color: ${props => props.theme.color.text.placeholder};
    }
  }
`

const TextField = ({ submitStyle, value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextField submitStyle={submitStyle} className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="text"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextField>
)

const StyledEmailField = styled.div`
  width: 100%;
  height: 100%;

  ${Label} {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
    color: ${props => props.theme.color.text.third};
  }

  input {
    height: 51px;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.sm};
    padding: 5px 20px 5px 20px;

    ${props => props.submitStyle === 'white' && css`
      border: 1px solid ${props => props.theme.color.text.placeholder};
    `}

    &::placeholder {
      color: ${props => props.theme.color.text.placeholder};
    }
  }
`

const EmailField = ({ submitStyle, className, value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledEmailField submitStyle={submitStyle} className={`${className ? `${className}` : ``} form-group`}>
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value || ''}
      onChange={onChange}
      type="email"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledEmailField>
)

const StyledPhoneField = styled.div`
  width: 100%;
  height: 100%;

  ${Label} {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
    color: ${props => props.theme.color.text.third};
  }

  input {
    height: 51px;
    width: 100%;
    border-radius: 8px;
    border: none;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.sm};
    padding: 5px 20px 5px 20px;

    ${props => props.submitStyle === 'white' && css`
      border: 1px solid ${props => props.theme.color.text.placeholder};
    `}

    &::placeholder {
      color: ${props => props.theme.color.text.placeholder};
    }
  }
`


const PhoneField = ({ submitStyle, value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledPhoneField submitStyle={submitStyle} className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      value={value}
      onChange={onChange}
      type="tel"
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledPhoneField>
)

const StyledTextAreaField = styled.div`
  width: 100%;
  height: 100%;

  ${Label} {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
    color: ${props => props.theme.color.text.third};
  }

  textarea {
    ${props => props.bigTextArea ? css`
      height: 256px;
    ` : css`
      height: 154px;
    `}
    width: 100%;
    border-radius: 8px;
    border: none;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.sm};
    padding: 15px 20px 5px 20px;

    ${props => props.submitStyle === 'white' && css`
      border: 1px solid ${props => props.theme.color.text.placeholder};
    `}

    &::placeholder {
      color: ${props => props.theme.color.text.placeholder};
    }
  }
`

const TextAreaField = ({ bigTextArea, submitStyle, value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledTextAreaField bigTextArea={bigTextArea} submitStyle={submitStyle} className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <textarea
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledTextAreaField>
)

const CheckboxField = ({ value, onChange, field: { id, type, cssClass, choices } }) => {
  const list = JSON.parse(choices);

  return (
    <div className="form-group">
      {list.map((checkbox, key) => (
        <div key={key} className="form-group__checkboxes">
          <input
            checked={value}
            onChange={onChange}
            type="checkbox"
            id={`input_${id}_${key + 1}`}
            className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
            name={`input_${id}_${key + 1}`}
            value={checkbox.value}
          />
          <label htmlFor={`input_${id}_${key + 1}`} className="checkbox-content">{checkbox.text}</label>
        </div>
      ))}
    </div>
  )
}

const StyledSelect = styled.div`

`

// Select or Dropdown
const SelectField = ({ field, onChange, value }) => {
  
  // Populate a options array
  const options = []
  JSON.parse(field.choices).forEach(choice => {
    options.push({
      value: choice.value,
      label: choice.text,
      type: 'select',
      name: `input_${field.id}`
    })
  })

  // Custom Select component
  const MySelect = props => (
    <Select
      {...props}
      onChange={props.onChange}
      options={props.options}
      placeholder={props.placeholder}
    />
  )

  return (
    <StyledSelect>
      <MySelect 
        options={options}
        onChange={onChange}
        value={options.filter(option => option.value === value)}
        placeholder={field.label}
        classNamePrefix="react-select"
      />
    </StyledSelect>
  )
}

const SubmitButton = ({ full, submitStyle, className, field }) => (
  <div className={`d-flex justify-content-center ${className ? `${className}` : ``}`}>
    {{
      'white': (
        <ButtonContrast isCustom>
          <button type="submit">
            {field.text}
          </button>
        </ButtonContrast>
      ),
      'green': (
        <ButtonOutline isCustom>
          <button type="submit">
            {field.text}
          </button>
        </ButtonOutline>
      ),
      default: (
        <ButtonDefault isCustom>
          <button type="submit">
            {field.text}
          </button>
        </ButtonDefault>
      )
    }[submitStyle]}
  </div>
)

const FileField = ({ value, onChange, field: { id, type, label, allowedExtensions, cssClass, placeholder } }) => {

  function handleChange(file) {
      onChange({
        target: {
          name: `input_${id}`,
          value: file
        }
      })
  }

  function handleFileUpload(e) {
    const { e: { target: { files } } } = e;

    for (let i = 0; i < files.length; i += 1) {
      handleChange(files[i])
    }
  }

  function handleFileDrop(e) {
    e.preventDefault();
    console.log(e);

    if (e.dataTransfer.items) {
      // Use DataTransferItemList interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.items.length; i += 1) {
        // If dropped items aren't files, reject them
        if (e.dataTransfer.items[i].kind === 'file') {
          handleChange(e.dataTransfer.items[i].getAsFile());
        }
      }
    } else {
      // Use DataTransfer interface to access the file(s)
      for (let i = 0; i < e.dataTransfer.files.length; i += 1) {
        handleChange(e.dataTransfer.files[i]);
      }
    }
  }

  return (
    <div className="form-upload" onDrop={handleFileDrop} onDragOver={e => e.preventDefault()}>
      <Label htmlFor={`input_${id}`}>
        {value ? (
          value.name
        ) : (
          <>{label}</>
        )}
      </Label>
      <input
        type="file"
        onChange={handleFileUpload}
        id={`input_${id}`}
        className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
        name={`input_${id}`}
        placeholder={placeholder}
        accept={allowedExtensions}
      />
    </div>
  )
}

const StyledDateField = styled.div`
  width: 100%;
  height: 100%;

  ${Label} {
    padding-left: 20px;
    margin-bottom: 0;
    font-size: ${props => props.theme.font.size.l};
    color: ${props => props.theme.color.text.third};
  }

  input {
    height: 51px;
    width: 100% !important;
    border-radius: 8px;
    border: none;
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.sm};
    padding: 5px 20px 5px 20px;
    -webkit-appearance: none;

    ${props => props.submitStyle === 'white' && css`
      border: 1px solid ${props => props.theme.color.text.placeholder};
    `}

    &::placeholder {
      color: ${props => props.theme.color.text.placeholder};
    }
  }
`

const DateField = ({ submitStyle, value, onChange, field: { id, type, label, labelPlacement, placeholder, isRequired, cssClass } }) => (
  <StyledDateField submitStyle={submitStyle} className="form-group">
    {labelPlacement !== "hidden_label" && (
    <Label htmlFor={`input_${id}`}>
      {label}
      {isRequired && (<Mandatory>*</Mandatory>)}
    </Label>
)}
    <input
      type="date"
      value={value}
      onChange={onChange}
      id={`input_${id}`}
      className={`${type} ${cssClass !== undefined ? cssClass : ""}`}
      name={`input_${id}`}
      required={isRequired}
      placeholder={placeholder}
    />
  </StyledDateField>
)

export const FormField = ({ bigTextArea, full, submitStyle, newsletter, className, field, fields, onChange }) => (
  <>
    {field.type === "text" && <TextField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "email" && <EmailField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "phone" && <PhoneField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "textarea" && <TextAreaField bigTextArea={bigTextArea} submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "checkbox" && <CheckboxField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "select" && <SelectField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "fileupload" && <FileField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "date" && <DateField submitStyle={submitStyle} className={className} onChange={onChange} value={fields[`input_${field.id}`]} field={field} />}
    {field.type === "submit" && <SubmitButton full={full} submitStyle={submitStyle} newsletter={newsletter} className={className} field={field} />}
  </>
)

export default GravityForm


