/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react'
import styled, { css } from 'styled-components'
import { useStaticQuery, graphql } from 'gatsby'

// Components
import CustomLink from 'components/shared/CustomLink'
import Image from 'components/shared/Image'

// Elements
import ButtonDefault from 'components/elements/ButtonDefault'

// Images
import tafelkleed from 'img/prof_footer_2.jpg'
import prof_facebook from 'img/prof_facebook_op.svg'
import prof_linkedin from 'img/prof_linkedin_op.svg'
import prof_twitter from 'img/prof_twitter_op.svg'
import prof_instagram from 'img/prof_instagram_op.svg'
import prof_youtube from 'img/prof_youtube_op.svg'

// Third Party
import { motion } from 'framer-motion'

const Socials = styled.div`
  position: absolute;
  bottom: -160px;
  left: -75px;

  @media screen and (max-width: 1199px) {
    display: none;
  }
`

const StyledHeader = styled.nav`
  background-color: #157B79;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  z-index: 10;
  height: 95px;

  @media screen and (max-width: 1199px) {
    background-color: ${props => props.theme.color.face.main};
  }
`

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
`

const BrandContainer = styled(motion.div)`
  width: 200px;
  height: 216px;
  background-color: ${props => props.theme.color.face.light};
  box-shadow: 0 0 20px rgba(0,0,0,0.3);
  position: absolute;
  left: -80px;
  top: 0;
  z-index: 5;
  overflow: hidden;

  @media screen and (max-width: 1199px) {
    left: 0;
  }

  @media screen and (max-width: 991px) {
    height: 120px;
  }

  @media screen and (max-width: 576px) {
    left: 20px;
  }

  & > svg {
    height: 30px;
    width: 30px;
  }
`

const Brand = styled(CustomLink)``

const NavButton = styled.button`
  width: 70px;
  height: 50px;
  /* padding: 0 10px; */
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background-color: #900C0C;
  
  height: 8px;
  width: 70%;
  margin: 5px auto;
`

const Logo = styled.div`
  position: relative;
  z-index: 4;

  h2 {
    font-size: ${props => props.theme.font.size.xxml};
    position: absolute;
    left: 60px;
    top: -5px;
  }

  h4 {
    font-size: ${props => props.theme.font.size.xxml};
    position: absolute;
    left: 80px;
    top: 45px;
  }
  
  h3 {
    font-size: ${props => props.theme.font.size.xxxxl};
    position: absolute;
    left: 25px;
    top: 10px;
  }
`

const LogoImage = styled(Image)`
  position: absolute !important;
  bottom: -20px;
  width: 100%;
  height: auto;
  z-index: 3;

  @media screen and (max-width: 991px) {
    display: none;
  }
`

const Header = ({ onPodium }) => {
  const {
    fields,
    socials,
    logo
  } = useStaticQuery(graphql`
    {
      fields: wordpressWpComponenten(wordpress_id: {eq: 97}) {
        acf {
          menu {
            link {
              title
              url
            }
          }
        }
      }

      socials: wordpressWpComponenten(wordpress_id: {eq: 98}) {
        acf {
          info {
            youtube
            instagram
            twitter
            linkedin
            facebook
          }
        }
      }

      logo: file(relativePath: {eq: "prof_logo.png"}) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  `)

  return (
    <StyledHeader>
      <Container className="container">
        <BrandContainer
          initial={{ y: -50, opacity: 0.4 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ type: 'spring', damping: 20 }}
        >
          <Brand to="/">
            <Logo>
              <h2>Prof</h2>
              <h3>&</h3>
              <h4>Prof</h4>
            </Logo>
            <LogoImage local src={logo} />
          </Brand>
        </BrandContainer>
        <Socials>
          <a href={socials.acf.info.youtube}> <img className="mr-3" src={prof_youtube} alt="" /></a>
          <a href={socials.acf.info.instagram}> <img className="mr-3" src={prof_instagram} alt="" /></a>
          <a href={socials.acf.info.twitter}> <img className="mr-3" src={prof_twitter} alt="" /></a>
          <a href={socials.acf.info.linkedin}> <img className="mr-3" src={prof_linkedin} alt="" /></a>
          <a href={socials.acf.info.facebook}> <img src={prof_facebook} alt="" /></a>
        </Socials>

        <MenuMobile fields={fields} />
        <MenuDesktop onPodium={onPodium} fields={fields} />
      </Container>
    </StyledHeader>
  )
}

const MenuWrapper = styled.div`
  background-color: ${props => props.theme.color.face.main};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  /* padding: 30px 0 0; */

  display: flex;
  align-items: center;

  & > ul {
    padding: 0 0 0;
    list-style-type: none;
    text-align: center;
    width: 60%;
    margin: 0 auto;

    & li {
      padding: 0;
      text-align: left;

      & a {
        font-size: ${props => props.theme.font.size.l};
        /* border-bottom: 1px solid #f1f1f1; */
        display: block;
        padding: 20px 0;
      }

      &:last-child a {
        border-bottom: none;
      }
    }
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 35px;
  top: 35px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before, &:after {
    background-color: ${props => props.theme.color.text.contrast};
    position: absolute;
    content: ' ';
    height: 33px;
    width: 5px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const StyledMenuMobile = styled.div`
  position: absolute;
  right: 25px;
  top: 25px;
`

const MenuMobile = ({ fields }) => {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (!isOpen) {
      document.querySelector('html').style.overflow = 'unset'
      return
    }
    
    document.querySelector('html').style.overflow = 'hidden'
  }, [isOpen])

  return (
    <StyledMenuMobile className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <NavButtonStripe index={1} />
        <NavButtonStripe index={2} />
        <NavButtonStripe index={3} />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => setIsOpen(!isOpen)}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </StyledMenuMobile>
  )
}

const StyledMenuDesktop = styled.ul`
  padding-left: 160px;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin-bottom: 0;

  @media screen and (max-width: 1199px) {
    padding-left: 230px;
  }
`

const MenuDesktop = ({ onPodium, fields }) => (
  <StyledMenuDesktop className="d-none d-lg-flex">
    <Menu onPodium={onPodium} fields={fields} />
  </StyledMenuDesktop>
)

const Item = styled.li`
  margin-right: 55px;
  & > a {
    color: ${props => props.theme.color.text.secondary};
    font-family: ${props => props.theme.font.family.secondary};
    font-size: ${props => props.theme.font.size.m};
    font-weight: ${props => props.theme.font.weight.xxs};
    position: relative;
    white-space: nowrap;

    strong {
      font-weight: ${props => props.theme.font.weight.xl};
    }

    &[aria-current] {
      &::before {
        content: "";
        position: absolute;
        width: 110%;
        left: -5%;
        bottom: -15px;
        height: 2px;
        background-color: ${props => props.theme.color.face.light};

        @media screen and (max-width: 991px) {
          bottom: 10px;
          width: 106%;
          left: -3%;
        }
      }
    }

    &:hover {
      text-decoration: none;
      color: ${props => props.theme.color.text.light};
      &::before {
        content: "";
        position: absolute;
        width: 110%;
        left: -5%;
        bottom: -15px;
        height: 2px;
        background-color: ${props => props.theme.color.face.light};

        @media screen and (max-width: 991px) {
          bottom: 10px;
          width: 106%;
          left: -3%;
        }
      }
    }
  }
`

const BackgroundContainer = styled.div`
  height: 95px;
  width: 600px;
  position: relative;
  right: 23px;

  @media screen and (max-width: 1199px) {
    display: none;
  }

  & > div {
    padding-left: 150px;

    @media screen and (max-width: 1199px) {
      padding-left: 60px;
    }
  }
`

const Menu = ({ onPodium, fields }) => (
  <>
    {fields.acf.menu.map(({ link: { url, title } }, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <Item key={index}>
        <CustomLink to={url}>
          PROF &
          <br className="d-lg-block d-none" />
          
          <strong>
            {` ${title}`}
          </strong>
          
        </CustomLink>
      </Item>
    ))}
    <BackgroundContainer>
      <div className="d-flex h-100 align-items-center">
        <div className="w-100 h-100 d-flex align-items-center">
          <ButtonDefault to={onPodium ? '/contact/' : '/podium/'}>
            {onPodium ? `Contact met Prof & Prof` : `Boek Prof & Prof`}
          </ButtonDefault>
        </div>
      </div>
    </BackgroundContainer>
  </>
)

export default Header
